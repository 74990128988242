<template>
  <form class="page-modal opacity-0 page-modal--active opacity">
    <div class="page-modal__container page-modal__container--top">
      <button
        @click="closeOrderForm"
        class="page-modal__close-button link link--white" aria-label="Закрыть меню" type="button"
      >
        <svg width="32" height="32"><use xlink:href="#arrow-right"></use></svg>
      </button>
      <h5 class="title title--size-small-fixed">Забронировать <br> кабинет</h5>
      <ul class="page-modal__list">

        <li class="page-modal__list-item" v-if="showEmailField">
          <!-- <h5>Укажите email для получения чеков</h5> -->
          <div class="dropdown-select">
            <p class="paragraph">Email</p>
            <input class="dropdown-select__wrapper" type="email" name="email" id="email" :value="orderParams.email" @input="setEmail">
          </div>
          <p v-if="emailError" class="input-field__error-msg paragraph page-modal__hint" style="display:block">Введите корректный email</p>
        </li>
        <li class="page-modal__list-item">
          <div class="dropdown-select">
            <p class="paragraph">Кабинет</p>
            <div class="dropdown-select__wrapper dropdown-select__wrapper--close js-dropdown-cabinet-id">
              <button
                @click="toggleShowCabinetDropdown"
                class="dropdown-select__btn" type="button"
              >
                <span>Каб. {{ orderParams.cabinet.number }}</span>
                <svg width="8" height="5" aria-hidden="true" class="dropdown-select__icon">
                  <use xlink:href="/img/sprite_auto.svg#icon-arrow-triangle"></use>
                </svg>
              </button>
              <div
                :class="showCabinetDropdown ? '' : 'dropdown-select__dropdown--close'"
                class="dropdown-select__dropdown"
              >
                <ul class="dropdown-select__list">
                  <li
                    v-for="cabinet in cabinets"
                    class="dropdown-select__item dropdown-select__item--purple-hover"
                  >
                    <div
                      @click="setCabinet(cabinet)"
                      class="dropdown-select__option"
                    >
                      <span class="dropdown-select__text">Каб. {{cabinet.number}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p class="paragraph page-modal__hint">{{ this.orderParams.cabinet.nameP }}</p>
        </li>
        <li class="page-modal__list-item">
          <div class="dropdown-select">
            <p class="paragraph">Дата</p>
            <div class="dropdown-select__wrapper dropdown-select__wrapper--close">
              <button
                @click="toggleShowDateDropdown"
                class="dropdown-select__btn" type="button"
              >
                <span>{{ orderParams.date.weekday_and_date }}</span>
                <svg width="8" height="5" aria-hidden="true" class="dropdown-select__icon">
                  <use xlink:href="/img/sprite_auto.svg#icon-arrow-triangle"></use>
                </svg>
              </button>
              <div
                :class="showDateDropdown ? '' : 'dropdown-select__dropdown--close'"
                class="dropdown-select__dropdown">
                <ul class="dropdown-select__list">
                  <li
                    v-for="date in days"
                    class="dropdown-select__item dropdown-select__item--purple-hover"
                  >
                    <div
                      @click="setDate(date)"
                      class="dropdown-select__option"
                    >
                      <span class="dropdown-select__text">{{ date.weekday_and_date }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="page-modal__list-item">
          <div class="dropdown-select">
            <p class="paragraph">Время, с</p>
            <div class="dropdown-select__wrapper dropdown-select__wrapper--close">
              <button
                @click="toggleShowTimeStartDropdown"
                class="dropdown-select__btn" type="button">
                <span>{{ orderParams.startTime.label }}</span>
                <svg width="8" height="5" aria-hidden="true" class="dropdown-select__icon">
                  <use xlink:href="/img/sprite_auto.svg#icon-arrow-triangle"></use>
                </svg>
              </button>
              <div
                :class="showTimeStartDropdown ? '' : 'dropdown-select__dropdown--close'"
                class="dropdown-select__dropdown">
                <ul class="dropdown-select__list">
                  <li
                    v-for="time in startTimes"
                    class="dropdown-select__item dropdown-select__item--purple-hover"
                  >
                    <div
                      @click="setStartTime(time)"
                      class="dropdown-select__option"
                    >
                      <span class="dropdown-select__text">{{time.label}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="page-modal__list-item">
          <div class="dropdown-select">
            <p class="paragraph">Длит., на</p>
            <div class="dropdown-select__wrapper dropdown-select__wrapper--close js-dropdown-cabinet-duration">
              <button
                @click="toggleShowDurationDropdown"
                class="dropdown-select__btn" type="button"
              >
                <span>{{ orderParams.duration.label }}</span>
                <svg width="8" height="5" aria-hidden="true" class="dropdown-select__icon">
                  <use xlink:href="/img/sprite_auto.svg#icon-arrow-triangle"></use>
                </svg>
              </button>
              <div
                :class="showDurationDropdown ? '' : 'dropdown-select__dropdown--close'"
                class="dropdown-select__dropdown"
              >
                <ul class="dropdown-select__list">
                  <li
                    v-for="duration in durations"
                    class="dropdown-select__item dropdown-select__item--purple-hover"
                  >
                    <div
                      @click="setDuration(duration)"
                      class="dropdown-select__option"
                    >
                      <span class="dropdown-select__text">{{ duration.label }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="page-modal__container page-modal__container--bottom">
      <span class="title title--desktop-size-small color-black">{{ price }} ₽</span>
      <div class="page-modal__wrapper">
        <button
          @click="createOrder"
          class="page-modal__button button">
          Забронировать
        </button>
        <button
          @click="closeOrderForm"
          class="page-modal__button button button--transparent button--purple"
        >
          Отмена
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  name: "OrderForm",
  props: {
    selectedSlot: {
      type: Object,
      required: true
    },
    toggleShowOrderForm: {
      type: Function,
      required: true,
    },
    closeOrderForm: {
      type: Function,
      required: true,
    },
    cabinets: {
      type: Array,
      required: true
    },
    days: {
      type: Array,
      required: true
    },
    durations: {
      type: Array,
      required: true
    },
    startTimes: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      showLocationDropdown: false ,
      showCabinetDropdown: false,
      showDateDropdown: false,
      showTimeStartDropdown: false,
      showDurationDropdown: false,
      emailError: false,
      showEmailField: gon.needed_client_email,
      price: 500,
      orderParams: {
        cabinet: { id: 7, number: 'С1' },
        duration: { value: 1, label: '1 чаc' },
        date: {value: '2024-01-01', label: 'Понедельник, 01.01'},
        startTime: { value: '7:00', label: '7:00' },
        max_persons_count: { value: 5, label: 5 },
        email: ''
      },
    }
  },
  methods: {
    checkEmail (value) {
      return /^\w+([\.!\#$%&'*+\/=?^_`{|}~-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(value)
    },
    setEmail(e) {
      this.orderParams.email = e.target.value
      this.emailError = !this.checkEmail(this.orderParams.email)
    },
    closeAllDropdown () {
      this.showLocationDropdown = false
      this.showCabinetDropdown = false
      this.showDateDropdown = false
      this.showTimeStartDropdown = false
      this.showDurationDropdown = false
    },
    toggleShowCabinetDropdown () {
      let oldValue = this.showCabinetDropdown
      this.closeAllDropdown();
      this.showCabinetDropdown = !oldValue
    },
    toggleShowDateDropdown () {
      let oldValue = this.showDateDropdown
      this.closeAllDropdown();
      this.showDateDropdown = !oldValue
    },
    toggleShowTimeStartDropdown () {
      let oldValue = this.showTimeStartDropdown
      this.closeAllDropdown();
      this.showTimeStartDropdown = !oldValue
    },
    toggleShowDurationDropdown () {
      let oldValue = this.showDurationDropdown
      this.closeAllDropdown();
      this.showDurationDropdown = !oldValue
    },
    setDuration(duration) {
      this.orderParams.duration = duration
      this.calcPrice()
      this.toggleShowDurationDropdown();
    },
    setCabinet(cabinet) {
      this.orderParams.cabinet = cabinet
      this.calcPrice()
      this.toggleShowCabinetDropdown()
    },
    setDate(date) {
      this.orderParams.date = date
      this.calcPrice()
      this.toggleShowDateDropdown()
    },
    setStartTime(startTime) {
      this.orderParams.startTime = startTime
      this.calcPrice()
      this.toggleShowTimeStartDropdown()
    },
    async calcPrice () {
      axios
        .get(`/api/price`, {
          params: {
            cabinet_id: this.orderParams.cabinet.id,
            duration: this.orderParams.duration.value,
            date: this.orderParams.date.day,
            start_time: this.orderParams.startTime.value,
            max_persons_count: this.orderParams.max_persons_count.value
          },
        })
        .then(response => {
          this.price = response.data.price
          })
    },
    createOrder(event) {
      event.preventDefault();
      if(this.showEmailField && !this.checkEmail(this.orderParams.email)) {
        this.emailError = !this.checkEmail(this.orderParams.email)
        return
      }
      axios.post('/lk/orders/create', {
        cabinet_id: this.orderParams.cabinet.id,
        duration: this.orderParams.duration.value,
        date: this.orderParams.date.day,
        start_time: this.orderParams.startTime.value,
        max_persons_count: this.orderParams.max_persons_count.value,
        email: this.orderParams.email
        }
      ).then(function(response) {
        if (response.data.success === true) {
          window.location.assign("/lk/orders/" + response.data.order_id);
        } else {
          window.location.assign("/lk/errors");
        }
      })
    },
  },
  mounted() {
    this.orderParams.cabinet = this.cabinets.find(cabinet => cabinet.id === this.selectedSlot.cabinet_id)
    let date = this.days.find(date => date.day === this.selectedSlot.date_str )
    this.orderParams.date = date
    this.orderParams.startTime.value = this.selectedSlot.start_str
    this.orderParams.startTime.label = this.selectedSlot.start_str

    this.calcPrice()
  },
}
</script>

