<template>
  <main>
    <SignInForm
      v-if="!clientSignedIn && showOrderForm"
      :close-sign-in-form="closeSignInForm"
    />
    <OrderForm
      v-if="clientSignedIn && showOrderForm"
      :toggle-show-order-form="toggleShowOrderForm"
      :close-order-form="closeOrderForm"
      :cabinets="cabinets"
      :days="formattedDays"
      :durations="durations"
      :startTimes="startTimes"
      :selectedSlot="selectedSlot"
    />
    <div class="overlay overlay--show" v-if="showOrderForm" @click="this.closeOrderForm"></div>
    <section class="cabinet-book bg-purple" id="book-cabinet">
      <div class="container">
        <div class="cabinet-book__booking">
          <div class="cabinet-book__container">
            <h3 class="title title--color-white">Забронировать кабинет</h3>
            <div class="button-switch-wrapper bg-purple" ref="calendarLocation" :class="[{'button-switch--fixed-vue' : this.slider.fixedLocation}]">
              <div class="button-switch bg-purple" >
                <button class="button-switch__button button-switch__button--active bg-apple" v-if="locationId === 2 || large_rooms_only">Страстной 4</button>
                <a v-else
                  @click="setLocation(2)"
                  class="button-switch__button"
                  :class="[{'border-right-1' : locationId === 4 }]"
                >Страстной 4
                </a>
                <button class="button-switch__button button-switch__button--active bg-coral" v-if="locationId === 3 || large_rooms_only">Страстной 6</button>
                <a v-else
                  @click="setLocation(3)"
                  class="button-switch__button"
                >Страстной 6
                </a>
                <button class="button-switch__button button-switch__button--active bg-pink" v-if="locationId === 4 || large_rooms_only">Мясницкая</button>
                <a v-else
                  @click="setLocation(4)"
                  class="button-switch__button"
                  :class="[{'border-left-1' : locationId === 2 }]"
                >Мясницкая
                </a>
              </div>
            </div>

            <div class="cabinet-book__plug"></div>
            <div class="cabinet-book__settings">
              <div class="cabinet-book__date-container">
                <p class="cabinet-book__date">Перейти к дате</p>
                <span class="select select--pointer">{{ this.currentDate }}</span>
                <flat-pickr v-if="this.days[0]" v-model="this.days[0]" :modelValue="this.days[0]" :config="config" :value="this.days[0]" class="visually-hidden" ref="flatpickr"></flat-pickr>
              </div>
              <div class="my-booking__checkbox">
                <input
                    v-model="large_rooms_only"
                    @change="getCalendarData()"
                    class="visually-hidden" type="checkbox" id="canceled-orders"
                >
                <label for="canceled-orders">от {{ min_square }} м<sup>2</sup></label>
              </div>
            </div>
          </div>
          <div class="calendar">
            <div
              class="slider js-calendar-head bg-purple" ref="calendarHead"
              :class="[{'calendar--fixed-vue' : this.slider.fixedHeader}]"
            >
              <button
                @click="calendarPrevDay"
                class="movement-button movement-button--transparent movement-button--back"
                :class="[{'movement-button--disabled' : slider.index === 0 && slider.offset === 0 }]"
              >
                <svg class="movement-button__icon icon icon--arrow" width="18" height="20">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </button>
              <button
                @click="calendarNextDay"
                class="movement-button movement-button--transparent"
              >
                <svg class="movement-button__icon icon icon--arrow" width="18" height="20">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </button>
              <div class="slider__wrap-list slider__wrap-list--overflow">
                <div class="slider__slide-list">
                  <div
                    v-for="day in formattedDays.slice(this.slider.index, this.slider.index + this.slider.currentDaysCount)" :key="day.day"
                    class="swiper-slide swiper-slide-active">
                    <div class="calendar__date">
                      <h3 class="subtitle subtitle--desktop-size-big">
                        {{ day.head_weekday }}
                      </h3>
                      <span class="calendar__date__divider">•</span>
                      <time class="calendar__date-time">{{day.short_date}}</time>
                    </div>
                    <ul class="calendar-list calendar-list--cabinets">
                      <li
                        v-for="cabinet in cabinets" :key="cabinet.id"
                        :class="['calendar-list__item', `${header_class[cabinet.location_id]}`]"
                      >
                        <span class="calendar-list__button">{{cabinet.number}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
            <div class="slider" ref="calendarBody" v-bind:style="{paddingTop: this.slider.paddingTopBody + 'px'}">
              <div class="slider__wrap-list slider__wrap-list--overflow">
                <div class="slider__slide-list swiper-wrapper">
                  <div
                    v-for="day in days.slice(this.slider.index, this.slider.index + this.slider.currentDaysCount)"
                    class="calendar__container swiper-slide"
                  >
                    <div class="calendar__wrapper">
                      <ul
                        v-for="cabinet in cabinets" :key="cabinet.id"
                        class="calendar-list"
                      >
                        <li
                          v-for="slot in timetable[day][cabinet.id]"
                          :class="slot_class(slot)"
                          @mouseover="setSelectedTime(day, cabinet.id, slot.start_str)"
                          @mouseleave="removeSelectedTime"
                        >
                          <button
                            v-if="slot['status'] === 'available'"
                            @click="openOrderForm(slot)"
                            class="calendar-list__button">
                            {{ slot.start_str }}
                          </button>

                          <span
                            v-if="slot['status'] === 'booked'"
                            class="calendar-list__text"
                          >
                            {{ slot.start_str }}
                          </span>

                          <svg
                            v-if="slot['status'] === 'disabled'"
                            width="32" height="20"
                          >
                            <use xlink:href="#icon-passed"></use>
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>


</template>

<script>
import OrderForm from './OrderForm.vue'
import SignInForm from './SignInForm.vue'
import axios from "axios";
import flatPickr from 'vue-flatpickr-component';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';


export default {
  name: "Calendar",
  components: {
    OrderForm,
    flatPickr,
    SignInForm,
  },
  data() {
    return {
      datepickerDate: null,
      currentDate: new Date().toLocaleDateString('ru-RU', {month: 'long', day: 'numeric'}),
      header_class: { '2': 'calendar-list__item--green', '3': 'calendar-list__item--orange', '4': 'calendar-list__item--pink' },
      config: {
        maxDate: null,
        enableTime: false,
        dateFormat: 'Y-m-d',
        position: 'auto left',
        locale: Russian,
        minDate: 'today',
        monthSelectorType: 'static',
        nextArrow: '<i class="calendar-button"></i>',
        prevArrow: '<i class="calendar-button calendar-button--reverse"></i>',
        closeOnSelect: false,
        defaultDate: new Date(),
        disableMobile: 'true',
        plugins: [
          ShortcutButtonsPlugin({
            button: {
              label: 'Выбрать дату',
              attributes: {
                class: 'link link--purple'
              }
            },
            onClick: (index, fp) => {
              this.datepickerDate = this.$refs.flatpickr.$el.value
              this.currentDate = new Date(this.$refs.flatpickr.$el.value).toLocaleDateString('ru-RU', {month: 'long', day: 'numeric'})
              this.getCalendarData({setIndex: 0, updateIndex: true})
              fp.close();
            }
          })
        ]
      },
      showOrderForm: false,
      locationId: gon.location_id,
      large_rooms_only: gon.large_rooms_only,
      days: [],
      startDate: '',
      formattedDays: [],
      timetable: {},
      cabinets: [],
      durations: [],
      startTimes: [],
      selectedSlot: {},
      hoveredSlots: [],
      min_square: 0,
      clientSignedIn: false,
      slider: {
        currentDaysCount: 1,
        fixedLocation: false,
        fixedHeader: false,
        paddingTopBody: 0,
        index: 0,
        offset: 0,
        arrowWidth: 335,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          740: {
            slidesPerView: 2,
          },
          1125: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize(); // Инициализируем при создании компонента
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const screenWidth = window.innerWidth;
      const breakpoints = Object.keys(this.slider.breakpoints).sort((a, b) => a - b);

      for (let i = 2; i >= 0; i--) {
        if (screenWidth >= breakpoints[i]) {
          this.slider.currentDaysCount = this.slider.breakpoints[breakpoints[i]].slidesPerView;
          break;
        }
      }
    },
    handleScroll() {
      if (this.$refs.calendarHead && this.$refs.calendarLocation) {
        const calendarBody = this.$refs.calendarBody;
        if (this.$refs.calendarHead.getBoundingClientRect().top < 0
        && this.$refs.calendarHead.getBoundingClientRect().top > -(calendarBody.offsetHeight)) {
          this.slider.fixedLocation = true
          this.slider.fixedHeader = true
          this.slider.paddingTopBody = this.$refs.calendarHead.offsetHeight + this.$refs.calendarLocation.offsetHeight;
        }

        if (calendarBody.getBoundingClientRect().top > 0
        || calendarBody.getBoundingClientRect().top + calendarBody.offsetHeight - this.$refs.calendarHead.offsetHeight - this.$refs.calendarLocation.offsetHeight < 0) {
          this.slider.fixedLocation = false
          this.slider.fixedHeader = false
          this.slider.paddingTopBody = 0;
        }
      }
    },
    slot_class(slot) {
      if (slot['status'] === 'booked') {
        return 'calendar-list__item calendar-list__item--booked'
      }

      if (slot['status'] === 'disabled') {
        return 'calendar-list__item calendar-list__item--passed'
      }

      if (slot['status'] === 'available') {
        if(this.hoveredSlots.filter(i => i.start == slot['start'] && i.cabinet_id == slot['cabinet_id']).length) {
          return 'calendar-list__item calendar-list__item--hover'
        } else {
          return 'calendar-list__item'
        }
      }
      return ''
    },
    toggleShowOrderForm () {
      this.showOrderForm = !this.showOrderForm
    },
    openOrderForm (slot) {
      document.body.classList.add('noscroll')
      this.selectedSlot = this.getFirstSelectedSlot()
      this.showOrderForm = true
    },
    closeOrderForm () {
      document.body.classList.remove('noscroll')
      this.showOrderForm = false
      this.selectedSlot = {}
    },
    closeSignInForm () {
      document.body.classList.remove('noscroll')
      this.showOrderForm = false
    },
    calendarNextDay() {
      this.slider.index = this.slider.index + 1
      if (this.slider.index + this.slider.currentDaysCount > 7) {
        this.slider.offset = 8 - this.slider.currentDaysCount + this.slider.offset
        this.getCalendarData({setIndex: 0, updateIndex: true})
      }
    },
    calendarPrevDay() {
      if (this.slider.index === 0 && this.slider.offset === 0) {
        return;
      }

      if (this.slider.index > 0 ) {
        this.slider.index = this.slider.index - 1
        return;
      }

      if (this.slider.index === 0 ) {
        if (this.slider.offset >= 3) {
          this.slider.offset -= 3;
          let setIndex = 2
          this.getCalendarData({setIndex: setIndex, updateIndex: true} )
        } else {
          let setIndex = this.slider.offset - 1
          this.slider.offset = 0
          this.getCalendarData({setIndex: setIndex, updateIndex: true} )
        }
      }
    },
    getFirstSelectedSlot() {
      let sortedSlot = this.hoveredSlots
      sortedSlot.sort((a, b) => this.timeToNumber(a.start_str) > this.timeToNumber(b.start_str) ? 1 : -1)
      return sortedSlot[0]
    },
    setLocation(locationId) {
      this.locationId = locationId;
      this.closeOrderForm();
      this.getCalendarData();
    },
    setSelectedTime(day, cabinetId, timeString){
      let currentSlot = this.timetable[day][cabinetId].filter((i) => i.start_str == timeString)
      let step = .5
      let numberTime = this.timeToNumber(timeString)
      let nextSlot = numberTime + step
      let stringNextSlot = this.numberToTime(nextSlot)

      let availableNextSlot = this.timetable[day][cabinetId].filter((i) => i.start_str == stringNextSlot && i.status == 'available')
      if(availableNextSlot.length) {
        this.hoveredSlots.push(currentSlot[0])
        this.hoveredSlots.push(availableNextSlot[0])
        return
      }
      let prewSlot = numberTime - step
      let stringPrewSlot =  this.numberToTime(prewSlot)
      let availablePrewSlot = this.timetable[day][cabinetId].filter((i) => i.start_str == stringPrewSlot && i.status == 'available')
      if(availablePrewSlot.length) {
        this.hoveredSlots.push(currentSlot[0])
        this.hoveredSlots.push(availablePrewSlot[0])
        return
      }
    },
    getCalendarData ( {setIndex = false, updateIndex = false} = {}) {
      axios
        .get(`/calendar/data`, {
          params: { location_id: this.locationId, offset: this.slider.offset, start_date: this.datepickerDate, large_rooms_only: this.large_rooms_only},
        })
        .then(response => {
          this.days = response.data.days
          this.config.maxDate = response.data.maxDay
          this.formattedDays = response.data.formattedDays
          this.cabinets = response.data.cabinets
          this.location = response.data.location
          this.timetable = response.data.timetable
          this.durations = response.data.durations
          this.startTimes = response.data.startTimes
          this.clientSignedIn = response.data.clientSignedIn
          this.large_rooms_only = response.data.large_rooms_only
          this.min_square = response.data.min_square
          if (updateIndex) {
            this.slider.index = setIndex
          }
          this.slider.offset = response.data.offset
          this.datepickerDate = null
        })
    },
    timeToNumber(time) { // приводим время вида '21:30' к виду 21.5
      let time_arr = time.split(':')
      return parseInt(time_arr[0]) + (parseInt(time_arr[time_arr.length - 1]) / 60)
    },
    numberToTime(number) {
      let hour = parseInt(number) < 10 ? `0${parseInt(number)}` : parseInt(number)
      let minutes = parseInt(number) == number ? '00' : (number - parseInt(number)) * 60
      return `${hour}:${minutes}`
    },
    removeSelectedTime() { // снимаем выделение времени записи
      this.hoveredSlots = []
    },
  },
  beforeMount() {
    this.getCalendarData();
  },
}
</script>

